import { createTheme } from "@mui/material";
import { MainSchema } from "./schemas";

const getTheme = (schema?: MainSchema) => {
  const textColor = "rgba(10, 10, 10, 0.87)";
  const fontFamily =
    'Lexend Deca, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue",sans-serif';

  const theme = createTheme({
    typography: {
      fontFamily: fontFamily,
      button: {
        textTransform: "none", // Disable auto-capitalization of buttons
      },
      h1: { fontSize: "3rem" },
      h2: { fontSize: "2rem" },
      caption: { fontSize: "1.25rem" },
      subtitle2: {
        color: '#777777',
        fontSize: '1rem'
      }
    },
    components: {
      MuiLink: {
        defaultProps: {
          // The fontFamily above does not apply to MuiLink even after removing this override entirely.
          fontFamily: fontFamily,
          color: textColor,
          underline: 'none'
        },
      },
    },
    palette: {
      primary: {
        main: schema?.primaryColor || "#A25F77",
      },
      secondary: {
        main: schema?.secondaryColor || "rgba(0, 0, 0, 0.12)",
      },
      text: {
        primary: textColor,
        secondary: "#000000",
      },
    },
  });
  return theme;
};

export default getTheme;
